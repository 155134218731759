import React from 'react';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faFacebookF from '@fortawesome/fontawesome-free-brands/faFacebookF';
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import RssIcon from '@fortawesome/fontawesome-free-solid/faRssSquare';

import ContactUsModal from '../ContactUsModal/ContactUsModal';
import { responsive } from '../../helpers/view';
import Logo from '../Logo';
import c from './Footer.less';

class Footer extends React.Component {
  state = {
    contactUsModalVisible: false,
  };

  currentYear = new Date().getFullYear();

  handleToggleContactUs = () => {
    this.setState((state) => ({
      contactUsModalVisible: !state.contactUsModalVisible,
    }));
  };

  handleOpenContactUsModal = (e) => {
    if (!responsive.isMobileOrTablet()) {
      e.preventDefault();
      this.handleToggleContactUs();
    }
  };

  render() {
    return (
      <footer className={c.footer}>
        <div className={c.innerFooter}>
          <div className={c.leftBlock}>
            <Logo className={c.logo} height={56} width={125} />
          </div>
          <div className={c.rightBlock}>
            <ul className={c.textLinks}>
              <li><Link to="/shop/contact" onClick={this.handleOpenContactUsModal}>Contact Us</Link></li>
              <li><Link to="/shop/feedback">Feedback</Link></li>
              <li><Link to="/shop/help">Help</Link></li>
              <li><Link to="/shop/terms">Terms of Use</Link></li>
              <li><Link to="/shop/policy">Privacy Policy</Link></li>
            </ul>
            <ul className={c.socialLinks}>
              <li className={c.fb}>
                <a href="https://www.facebook.com/mp3caprice" aria-label="Facebook page">
                  <FontAwesomeIcon icon={faFacebookF} className={c.socialIcon} />
                </a>
              </li>
              <li className={c.tw}>
                <a href="https://twitter.com/mp3capricecom" aria-label="Twitter">
                  <FontAwesomeIcon icon={faTwitter} className={c.socialIcon} />
                </a>
              </li>
              <li className={c.rss}>
                <a href="/rss/month.xml" aria-label="Monthly releases RSS feed">
                  <FontAwesomeIcon icon={RssIcon} className={c.socialIcon} />
                </a>
              </li>
            </ul>
          </div>
          <span className={c.copyrights}>&copy;{this.currentYear} Mp3Caprice
          </span>
        </div>
        <ContactUsModal visible={this.state.contactUsModalVisible} onClose={this.handleToggleContactUs} />
      </footer>
    );
  }
}

export default Footer;
